import { lazy } from "react";
import WithSuspense from "../components/loaders/WithSuspense";
import { PATHS } from "./constants";
import { Navigate } from "react-router-dom";

const { HOME, LOGIN } = PATHS;

const Home = WithSuspense(lazy(() => import("../pages/Home")));
const Auth = WithSuspense(lazy(() => import("../pages/Auth")));

export const ROUTES = [
  { path: HOME, element: <Home /> },
  { path: LOGIN, element: <Auth /> },
  { path: "*", element: <Navigate to="/" replace /> },
];
